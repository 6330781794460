<template>
  <div class="container mt-4">
    <h4 class="ml-sm-2 ml-md-4 ml-lg-4">{{ title }}</h4>
    <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
      <a-form-item label="Rasm">
        <a-input type="file" @change="imgUpload" />
        <img
          v-if="img || cPreviewImg"
          :src="cPreviewImg"
          :alt="oldImg"
          width="100"
          height="100"
          class="mt-3"
        />
      </a-form-item>
      <a-form-item label="Link">
        <a-input type="url" v-model="value.url" :value="value.url" />
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    title: String,
    img: Boolean,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      oldImg: '',
      previewImg: '',
    };
  },
  computed: {
    cPreviewImg() {
      return this.oldImg
        ? this.previewImg
          ? URL.createObjectURL(this.previewImg)
          : this.oldImg
        : this.oldImg;
    },
  },
  methods: {
    imgUpload(e) {
      const files = e.target.files[0];
      this.oldImg = files;
      this.previewImg = files;
      const imgobj = {
        files,
        obj: true,
      };
      this.value.image = imgobj;
      this.$notification['success']({
        message: 'Success',
        description: "Rasm o'zgardi va qo'shildi ):",
      });
    },
  },

  updated() {
    if (!this.oldImg) {
      this.oldImg = this.value.image;
    }
  },
};
</script>

<style scoped>
input[type='file'] {
  cursor: pointer;
  width: 100%;
  height: 37px;
  overflow: hidden;
  border-radius: 5px;
  background-color: white;
}
input[type='file']:focus {
  outline: none;
}

input[type='file']:before {
  width: 100%;
  font-size: 15px;
  line-height: 27px;

  content: 'Rasm Yuklash';
  display: inline-block;
  background: white;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
}

input[type='file']::-webkit-file-upload-button {
  visibility: hidden;
}
</style>
