<template>
  <section class="">
    <partner-create
      v-model="partner"
      :img="img"
      title="Hamkor malumotlarini o'zgartirish"
    ></partner-create>
    <div class="container flex">
      <a-button class="bg-danger text-white" @click="deletePartner">
        O'chirish
      </a-button>
      <a-button class="bg-warning text-white ml-2" @click="editPartner">
        O'zgartish
      </a-button>
    </div>
    <modal v-if="inValid" title="Xato" @close="closeModal">
      <p class="text-white bg-danger p-3 mt-3">
        Iltimos partner link va rasmni kiriting
      </p>
    </modal>
  </section>
</template>

<script>
import PartnerCreate from '../../components/Partner/PartnerCreate.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    PartnerCreate,
  },
  props: ['partnerId'],
  data() {
    return {
      img: true,
      inValid: false,
      partner: {
        url: '',
        image: '',
      },
    };
  },
  methods: {
    ...mapActions({
      patchPartner: 'partner/patchPartner',
      getOnePartner: 'partner/getOnePartner',
      deleteOnePartner: 'partner/deletePartner',
    }),
    closeModal(bool) {
      this.inValid = bool;
    },
    editPartner() {
      const obj = {
        url: this.partner.url,
        image: this.partner.image,
      };
      for (let i in obj) {
        if (obj[i] === '') {
          this.inValid = true;
          return;
        }
      }

      if (!obj.image.obj) {
        delete obj.image;
      } else {
        obj.image = this.partner.image.files;
      }
      this.patchPartner({ obj: obj, id: this.partnerId })
        .then(() => {
          this.$notification['success']({
            message: "Hamkor o'zgardi",
            description: "Serverda hamkor o'zgartirildi ):",
          });
          setTimeout(() => {
            this.$router.push({ name: 'Partner' });
          }, 1000);
        })
        .catch((error) => {
          this.$notification['error']({
            message: 'Xatolik',
            description: 'Serverda Xatolik Boldi ):' + error.message,
          });
          setTimeout(() => {
            this.$router.push({ name: 'Partner' });
          }, 1000);
        });
    },
    deletePartner() {
      this.deleteOnePartner(this.partnerId)
        .then(() => {
          this.$notification['success']({
            message: "O'chirildi",
            description: "Serverda hamkor o'chirildi ):",
          });
          setTimeout(() => {
            this.$router.push({ name: 'Partner' });
          }, 1000);
        })
        .catch((error) => {
          this.$notification['error']({
            message: 'Xatolik',
            description: 'Serverda Xatolik Boldi ):' + error.message,
          });
          setTimeout(() => {
            this.$router.push({ name: 'Partner' });
          }, 1000);
        });
    },
  },

  created() {
    this.getOnePartner(this.partnerId).then((data) => {
      this.partner = data;
    });
  },
};
</script>
